import React from "react"
import PropTypes from "prop-types"
import BackgroundImage from "gatsby-background-image"

import Cookies from "./cookies"
import Navbar from "./blog-navbar"
import Footer from "./footer"
import "../styles/components/blog-layout.scss"

const MainStripe = ({ stripeImgFluid }) => (
  <BackgroundImage Tag="div" className="main-stripe" fluid={stripeImgFluid} />
)

const TransparentStripeTop = () => <div className="overlay is-top"></div>

const Layout = ({ children, stripeImgFluid, pageName, noHero }) => {
  return (
    <>
      <Cookies />
      <header>
        {noHero ? (
          <></>
        ) : (
          <div className="main-stripes-blog-wrapper">
            <TransparentStripeTop />
            <MainStripe stripeImgFluid={stripeImgFluid} />
          </div>
        )}
        <Navbar isTransparent={noHero} />
      </header>
      {noHero ? <></> : <div style={{ height: 400 }} />}

      <div>
        <main className={pageName}>{children}</main>
      </div>
      <Footer stripeClass="is-blue-violet" />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  stripeClass: PropTypes.string,
  pageName: PropTypes.string.isRequired,
}

Layout.defaultProps = {
  stripeClass: "is-transparent",
}
export default Layout

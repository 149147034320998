import React from "react"
import PropTypes from "prop-types"

// Styles
import "../styles/components/blog-author.scss"

const BlogAuthor = ({ bgImg, name, className }) => {
  return (
    <div className={`blog-author-wrapper is-flex is-vcentered ${className}`}>
      <div
        className="author-image has-margin-right-4"
        style={{
          backgroundImage: `url(${bgImg})`,
        }}
      />
      <span>by {name}</span>
    </div>
  )
}
BlogAuthor.propTypes = {
  bgImg: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
}
export default BlogAuthor

import React from "react"
import { Link } from "gatsby-plugin-intl"

// Icons
import TwispayLogo from "../assets/icons/twispay-blog-logo.svg"
import TwispayLogoColored from "../assets/icons/twispay-blog-logo-colored.svg"

// Styles
import "../styles/components/blog-navbar.scss"

const BlogNavbar = ({ isTransparent }) => {
  const tags = ["News", "Tech", "Company", "Edu"]
  return (
    <nav className="blog-navbar is-flex is-vcentered is-hspaced">
      <div className="main-logo">
        <Link className="has-text-white" to="/blog">
          {isTransparent ? <TwispayLogoColored /> : <TwispayLogo />}
        </Link>
      </div>
      <div className="main-menu is-flex is-centered-centered">
        {tags.map(tag => (
          <div key={tag} className="has-margin-right-5 ">
            <Link
              className={`${
                isTransparent ? "has-text-primary" : "has-text-white"
              }`}
              to={`/blog/tag/${tag.toLowerCase()}`}
            >
              {tag}
            </Link>
          </div>
        ))}
      </div>
    </nav>
  )
}

export default BlogNavbar

import React from "react"
import { Link } from "gatsby-plugin-intl"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Typography from "typography"
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import Layout from "../components/blog-layout"
import BlogAuthor from "../components/blog-author"

import FacebookIcon from "../assets/icons/facebook.svg"
import TwitterIcon from "../assets/icons/twitter.svg"
import LinkedinLogo from "../assets/icons/linkedin.svg"
import "../styles/templates/blog-post.scss"

const typography = new Typography({
  baseFontSize: "16px",
  baseLineHeight: 1.5,
  headerFontFamily: ["Open Sans", "sans-serif"],
  bodyFontFamily: ["Open Sans", "sans-serif"],
  bodyColor: "#575f7f",
})

export default function Template({ data, location }) {
  const { contentfulBlogPost } = data
  let options = {
    renderNode: {
      "embedded-asset-block": node => (
        <img
          src={node.data.target.fields.file.en.url}
          alt={node.data.target.fields.title.en}
        />
      ),
      "embedded-entry-block": node => (
        <pre>
          <code>{node.data.target.fields.code.en} </code>
        </pre>
      ),
    },
  }
  return (
    <Layout
      stripeImgFluid={contentfulBlogPost.headerImage.fluid}
      pageName="blog-post"
    >
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
        title={`${contentfulBlogPost.title} | Twispay`}
        meta={[
          {
            name: `description`,
            content: contentfulBlogPost.shortDescription,
          },
          {
            property: `og:title`,
            content: contentfulBlogPost.title,
          },
          {
            property: `og:description`,
            content: contentfulBlogPost.shortDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:image`,
            content: `https:${contentfulBlogPost.headerImage.fluid.src}`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: contentfulBlogPost.author.name,
          },
          {
            name: `twitter:title`,
            content: contentfulBlogPost.title,
          },
          {
            name: `twitter:description`,
            content: contentfulBlogPost.shortDescription,
          },
          {
            property: `twitter:image`,
            content: `https:${contentfulBlogPost.headerImage.fluid.src}`,
          },
        ]}
      >
        <style>{typography.toString()}</style>
      </Helmet>
      <section className="section">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-three-quarters">
              <div className="post-details is-card has-text-centered">
                <h1 className="title is-4 ">{contentfulBlogPost.title}</h1>
                <BlogAuthor
                  bgImg={contentfulBlogPost.author.image.fixed.src}
                  name={contentfulBlogPost.author.name}
                  className="is-centered-centered has-margin-bottom-5"
                />
                <div className="tags-wrapper is-flex is-centered-centered">
                  {contentfulBlogPost.tags.map(tag => (
                    <Link
                      key={tag}
                      to={`/blog/tag/${tag}`}
                      className="tag is-primary is-light has-margin-left-3 has-margin-right-3 has-margin-bottom-4"
                    >
                      {tag}
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-three-quarters">
              {documentToReactComponents(
                contentfulBlogPost.content.json,
                options
              )}
            </div>
          </div>
          <div className="social-wrapper has-text-centered has-margin-bottom-7">
            <h4 className="is-title">Tell the World!</h4>
            <FacebookShareButton url={location.href}>
              <FacebookIcon />
            </FacebookShareButton>
            <TwitterShareButton
              url={location.href}
              className="has-margin-left-4 has-margin-right-4"
            >
              <TwitterIcon />
            </TwitterShareButton>
            <LinkedinShareButton url={location.href}>
              <LinkedinLogo />
            </LinkedinShareButton>
          </div>
          <div className="author-footer has-text-centered">
            <div
              className="author-image has-margin-bottom-4"
              style={{
                backgroundImage: `url(${contentfulBlogPost.author.image.fixed.src})`,
              }}
            />
            <p className="is-size-5">{contentfulBlogPost.author.name}</p>
            <p>
              Find me on{" "}
              <a
                className="has-text-secondary"
                href={contentfulBlogPost.author.linkedInUrl}
              >
                LinkedIn
              </a>
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($contentful_id: String!) {
    contentfulBlogPost(contentful_id: { eq: $contentful_id }) {
      headerImage {
        fluid(maxHeight: 745) {
          base64
          aspectRatio
          src
          sizes
          srcSet
        }
      }
      title
      shortDescription
      tags
      author {
        name
        linkedInUrl
        image {
          fixed(width: 120) {
            src
          }
        }
      }
      content {
        json
      }
    }
  }
`
